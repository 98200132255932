<template>
    <b-row>
        <b-col cols="12">
            <cluji-card title="Intervalle considéré">
                <b-col md="8" offset-md="2" lg="6" offset-lg="3" xl="4" offset-xl="4">
                    <multi-select v-model="selectedMonth"
                                  :options="monthsAvailable"
                                  :multiple="false"
                                  :close-on-select="true"
                                  track-by="value"
                                  :clear-on-select="false"
                                  :preserve-search="false"
                                  label="name"
                                  :preselect-first="true"/>
                </b-col>
            </cluji-card>
        </b-col>
        <b-col xl="6">
            <leaderboard-card title="Connexions" :data="connexionsData"/>
        </b-col>
        <b-col xl="6">
            <leaderboard-card title="Commentaires sur les événements" :data="commentairesData"/>
        </b-col>
        <b-col xl="6">
            <leaderboard-card title="Slimes capturés" :data="slimesNumberData"/>
        </b-col>
        <b-col xl="6">
            <leaderboard-card title="Slimes" :data="slimesData">
                <template #text="{item}">
                    {{ getSlimeNom(item) }} : {{ item.xp }}xp (
                    <user-name :user="item.utilisateur"/>
                    )
                </template>
            </leaderboard-card>
        </b-col>
        <b-col xl="6">
            <leaderboard-card title="Tétris : nombre de parties" :data="tetrisNumberData"/>
        </b-col>
        <b-col xl="6">
            <leaderboard-card title="Tétris : score" :data="tetrisScoreData"/>
        </b-col>
    </b-row>
</template>

<script>
    import moment from 'moment';

    const ClujiCard       = () => import('@/components/ClujiCard');
    const UserName        = () => import('@/components/UserName');
    const LeaderboardCard = () => import('@/components/leaderboard/LeaderboardCard');
    const MultiSelect     = () => import('@/components/MultiSelect');

    import alert                             from '@/util/alert';
    import {apiPath}                         from '@/util/http';
    import {getSlimeNom, requireSlimeImgSrc} from '@/util/slime';
    import {ucFirst}                         from '@/util/text';

    export default {
        name: 'Leaderboard',
        components: {MultiSelect, ClujiCard, UserName, LeaderboardCard},
        data: () => ({
            selectedMonth: null,
            connexionsData: [],
            commentairesData: [],
            slimesNumberData: [],
            slimesData: [],
            tetrisNumberData: [],
            tetrisScoreData: []
        }),
        computed: {
            monthsAvailable() {
                let months = [
                    {
                        value: null,
                        name: 'Depuis toujours'
                    }
                ];

                let date        = moment().endOf('month');
                let startOfSite = moment('2019-09-05');

                while (date > startOfSite) {
                    months.push({
                        value: date.format('YYYY-MM'),
                        name: ucFirst(date.format('MMMM YYYY'))
                    });
                    date = date.subtract(1, 'months');
                }

                return months;
            }
        },
        methods: {
            getSlimeNom,
            dateOption() {
                return this.selectedMonth ? {month: this.selectedMonth.value} : {};
            },
            loadConnexions() {
                return this.axios.get(apiPath('leaderboard_connexions', this.dateOption()))
                    .then(response => this.connexionsData = response.data)
                    .catch(() => this.$toaster.error('Impossible de charger les données des connexions'));
            },
            loadCommentaires() {
                return this.axios.get(apiPath('leaderboard_comments', this.dateOption()))
                    .then(response => this.commentairesData = response.data)
                    .catch(() => this.$toaster.error('Impossible de charger les données des commentaires'));
            },
            loadSlimesNumber() {
                return this.axios.get(apiPath('leaderboard_slimes_number', this.dateOption()))
                    .then(response => this.slimesNumberData = response.data)
                    .catch(() => this.$toaster.error('Impossible de charger les données des slimes capturés'))
            },
            loadSlimes() {
                return this.axios.get(apiPath('leaderboard_slimes', this.dateOption()))
                    .then(response => this.slimesData = response.data.map(slime => ({
                        ...slime,
                        image: requireSlimeImgSrc(slime)
                    })))
                    .catch(() => this.$toaster.error('Impossible de charger les données des slimes'))
            },
            loadTetrisNumber() {
                return this.axios.get(apiPath('leaderboard_tetris_number', this.dateOption()))
                    .then(response => this.tetrisNumberData = response.data)
                    .catch(() => this.$toaster.error('Impossible de charger les nombres de parties de tétris'));
            },
            loadTetrisScore() {
                return this.axios.get(apiPath('leaderboard_tetris_score', this.dateOption()))
                    .then(response => this.tetrisScoreData = response.data.map(
                        ({utilisateur, score}) => ({utilisateur, count: score})
                    ))
                    .catch(() => this.$toaster.error('Impossible de charger les scores des parties de tétris'))
            },
            loadData() {
                alert.loading();
                this.loadConnexions()
                    .then(() => this.loadCommentaires())
                    .then(() => this.loadSlimesNumber())
                    .then(() => this.loadSlimes())
                    .then(() => this.loadTetrisNumber())
                    .then(() => this.loadTetrisScore())
                    .finally(alert.stopLoading);
            }
        },
        watch: {
            selectedMonth(val) {
                this.selectedMonth = val;
                this.loadData();
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>
